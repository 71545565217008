import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, ShieldCheckIcon, XIcon, UsersIcon } from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { MdOutlineAutoGraph } from 'react-icons/md';
import { BsArrowReturnRight } from 'react-icons/bs';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import ThankYouLogo from '../images/logo.svg';
import ThankYouSmallLogo from '../images/small_logo.svg';

const footerNavigation = [
  // {
  //   name: 'Facebook',
  //   href: 'https://www.facebook.com/Thank-You-105651007800862/',
  //   icon: (props) => (
  //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
  //       <path
  //         fillRule="evenodd"
  //         d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
  //         clipRule="evenodd"
  //       />
  //     </svg>
  //   ),
  // },
  // {
  //   name: 'Twitter',
  //   href: 'https://twitter.com/ThankYouio',
  //   icon: (props) => (
  //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
  //       <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
  //     </svg>
  //   ),
  // },
  {
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/company/thank-youio/',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.105,4,24,4z M10.954,22h-2.95 v-9.492h2.95V22z M9.449,11.151c-0.951,0-1.72-0.771-1.72-1.72c0-0.949,0.77-1.719,1.72-1.719c0.948,0,1.719,0.771,1.719,1.719 C11.168,10.38,10.397,11.151,9.449,11.151z M22.004,22h-2.948v-4.616c0-1.101-0.02-2.517-1.533-2.517 c-1.535,0-1.771,1.199-1.771,2.437V22h-2.948v-9.492h2.83v1.297h0.04c0.394-0.746,1.356-1.533,2.791-1.533 c2.987,0,3.539,1.966,3.539,4.522V22z" />
      </svg>
    ),
  },
];

const products = [
  {
    name: 'Marketing',
    description: "Mesurez vos campagnes en respectant l'ePrivacy",
    href: 'marketing-analytics',
    icon: MdOutlineAutoGraph,
  },
  {
    name: 'Affiliate',
    description: "Boostez votre programme d'affiliation",
    href: 'affiliate-marketing',
    icon: UsersIcon,
    subItems: [
      {
        name: 'Attribution',
        href: 'affiliate-marketing/attribution-remuneration',
      },
      {
        name: 'Détection des fraudes',
        href: 'affiliate-marketing/detection-fraudes-affiliation',
      },

      {
        name: 'Validation des ventes',
        href: 'affiliate-marketing/validation-ventes-affiliation',
      },
    ],
  },
  {
    name: 'Brand Safety',
    description: "Protégez l'achat de votre marque en SEA",
    href: 'brand-safety',
    icon: ShieldCheckIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function PageLayout({ children }) {
  return (
    <div className="bg-white">
      <header>
        <Popover className="relative bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="flex justify-between items-center border-b-2 border-gray-100 py-4 md:justify-start md:space-x-10">
              <div className=" flex justify-start lg:w-0 lg:flex-1">
                <Link to="/">
                  <span className="sr-only">Thank-You Analytics</span>
                  <img
                    className="h-10 w-auto sm:h-12"
                    src={ThankYouLogo}
                    alt="Thank-You Analytics Logo"
                  />
                </Link>
              </div>
              <div className="-mr-2 -my-2 md:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-brand">
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <Popover.Group as="nav" className="hidden md:flex space-x-10">
                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? 'text-gray-900' : 'text-gray-500',
                          'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-brand'
                        )}
                      >
                        <span>Solutions</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'text-gray-600' : 'text-gray-400',
                            'ml-2 h-5 w-5 group-hover:text-gray-500'
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                              {products.map((item) => (
                                <div className="" key={item.name}>
                                  <Link
                                    to={`/${item.href}`}
                                    className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                  >
                                    <img
                                      className="flex-shrink-0 h-6 w-6 text-green-brand"
                                      src={ThankYouSmallLogo}
                                      alt="Thank-You Analytics Logo"
                                    />

                                    <div className="ml-2">
                                      <p className="text-base font-title text-gray-900">
                                        {item.name}
                                      </p>
                                      <p className="mt-1 text-sm text-gray-500">
                                        {item.description}
                                      </p>
                                    </div>
                                  </Link>
                                  {item.subItems && (
                                    <div className="mt-2 flex flex-col">
                                      {item.subItems.map((subItem) => (
                                        <Link
                                          key={subItem.name}
                                          to={`/${subItem.href}`}
                                          className="ml-8 px-3 py-2 flex items-center rounded-lg hover:bg-gray-50"
                                        >
                                          <BsArrowReturnRight
                                            className="flex-shrink-0 h-4 w-4 text-green-brand"
                                            aria-hidden="true"
                                          />
                                          <div className="ml-4">
                                            <p className="text-base font-medium text-gray-900">
                                              {subItem.name}
                                            </p>
                                          </div>
                                        </Link>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>

                <Link
                  to="/ressources"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Ressources
                </Link>
                <Link
                  to="/contact"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Contact
                </Link>
              </Popover.Group>
              <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                <a
                  href="https://app.thank-you.io"
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-3 py-2 border border-green-brand hover:border-green-brandHover rounded-md shadow-sm text-sm font-medium text-green-brand hover:text-green-brandHover bg-white hover:bg-white"
                >
                  Se connecter
                </a>

                <Link
                  to="/contact"
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-3 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-brand hover:bg-green-brandHover"
                >
                  Demande de démo
                </Link>
              </div>
            </div>
          </div>

          <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                      <img className="h-8 w-auto" src={ThankYouLogo} alt="Thank-You" />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-brand">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-6">
                    <nav className="grid gap-y-8">
                      {products.map((item) => (
                        <div className="" key={item.name}>
                          <Link
                            to={`/${item.href}`}
                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                          >
                            <img
                              className="flex-shrink-0 h-6 w-6 text-green-brand"
                              src={ThankYouSmallLogo}
                              alt="Thank-You Analytics Logo"
                            />

                            <span className="ml-2 text-base font-title text-gray-900">
                              {item.name}
                            </span>
                          </Link>
                          {item.subItems && (
                            <div className="mt-2 flex flex-col">
                              {item.subItems.map((subItem) => (
                                <Link
                                  key={subItem.name}
                                  to={`/${subItem.href}`}
                                  className="ml-8 px-3 py-2 flex items-center rounded-lg hover:bg-gray-50"
                                >
                                  <BsArrowReturnRight
                                    className="flex-shrink-0 h-4 w-4 text-green-brand"
                                    aria-hidden="true"
                                  />
                                  <div className="ml-4">
                                    <p className="text-base font-medium text-gray-900">
                                      {subItem.name}
                                    </p>
                                  </div>
                                </Link>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                      <Link to="/ressources" className="rounded-md hover:bg-gray-50">
                        Ressources
                      </Link>
                      <Link to="/contact" className="rounded-md hover:bg-gray-50">
                        Contact
                      </Link>
                    </nav>
                  </div>
                </div>
                <div className="py-6 px-5 space-y-6">
                  <div className="flex space-x-4">
                    <a
                      href="https://app.thank-you.io"
                      className="flex-1 self-center text-center px-3 py-2 border border-green-brand hover:border-green-brandHover rounded-md shadow-sm text-sm font-medium text-green-brand hover:text-green-brandHover bg-white hover:bg-white"
                    >
                      Se connecter
                    </a>

                    <Link
                      to="/contact"
                      className="flex-1 self-center text-center px-3 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-brand hover:bg-green-brandHover"
                    >
                      Demande de démo
                    </Link>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </header>
      <main>{children}</main>
      <footer className="bg-white">
        <div className="border-t-2 border-gray-100 max-w-7xl mx-auto py-8 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center space-x-6 md:order-2">
            {footerNavigation.map((item) => (
              <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <div className="mt-8 md:mt-0 md:order-1">
            <div className="flex space-x-5">
              <p className="text-center text-base text-gray-400">Copyright ©2023</p>
              <Link className="text-gray-400 text-base" to="/mentions-legales">
                Mentions légales
              </Link>
              <Link className="text-gray-400 text-base" to="/charte">
                Charte
              </Link>
              <Link className="text-gray-400 text-base" to="/gestion-cookies">
                Gestion des cookies
              </Link>
              <Link className="text-gray-400 text-base" to="/CGU">
                Conditions générales d'utilisation
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
